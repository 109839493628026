@tailwind base;
@tailwind components;
@tailwind utilities;


.newspaper-container {
    background-color: #f5f5dc; /* Vintage beige paper color */
    padding: 2rem;
    max-width: 900px;
    width: 100%;
    border-radius: 8px;
    position: relative;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(
      to bottom right,
      rgba(255, 255, 224, 0.9),
      rgba(240, 230, 140, 0.9)
    );
  }
  
  .paper-background {
    background: #fdf5e6; /* Light beige color */
    border: 2px dashed #b5651d; /* Torn effect */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.15);
    position: relative;
  }
  
  .vintage-title {
    font-family: 'Georgia', serif;
    color: #b5651d;
  }
  
  .timetable-image {
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    filter: sepia(0.5) contrast(1.2); /* Vintage color effect */
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  /* Popup Image Styling */
  .popup-image {
    width: 100%;
    max-height: 90vh;
    border-radius: 8px;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .newspaper-container {
      padding: 1rem;
    }
  
    .vintage-title {
      font-size: 2rem;
    }
  
    .timetable-image {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .newspaper-container {
      padding: 0.5rem;
    }
  
    .vintage-title {
      font-size: 1.5rem;
    }
  }
  